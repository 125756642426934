import { storeToRefs } from "pinia";
import { useShippingStore } from "@/stores/shipping";

export default defineNuxtRouteMiddleware((to) => {
  const { isHaveInputedStep1, isHaveInputedStep2 } =
    storeToRefs(useShippingStore());
  if (to?.name !== "atur-order-pengirim-step-1" && !isHaveInputedStep1.value) {
    abortNavigation();
    return navigateTo("/atur-order/pengirim/step-1");
  } else if (
    to?.name !== "atur-order-pengirim-step-2" &&
    isHaveInputedStep1.value &&
    !isHaveInputedStep2.value
  ) {
    abortNavigation();
    return navigateTo("/atur-order/pengirim/step-2");
  }
});
